import { LayoutForgotPassword } from '~/components/Account/UnLogged/ForgotPassword'
import { LayoutLogin } from '~/components/Account/UnLogged/Login'
import { LayoutRegister } from '~/components/Account/UnLogged/Register'
import { LayoutResetPassword } from '~/components/Account/UnLogged/ResetPassword'

import withoutAuthentication from '~/hocs/withoutAuthentication'

export const WithoutAuthenticationLogin = withoutAuthentication(LayoutLogin)

export const WithoutAuthenticationRegister = withoutAuthentication(
  LayoutRegister,
)

export const WithoutAuthenticationForgotPassword = withoutAuthentication(
  LayoutForgotPassword,
)

export const WithoutAuthenticationResetPassword = withoutAuthentication(
  LayoutResetPassword,
)
