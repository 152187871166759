import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ErrorOption } from 'react-hook-form'
import { SINGLE_ROUTES } from '~/lib/constants'
import { resetPassword } from '~/lib/handle-api'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useCurrency from '~/hooks/useCurrency'
import useSingleRoutesRedirect from '~/hooks/useSingleRoutesRedirect'

export default function useShopifyResetPasswordMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const redirect = useSingleRoutesRedirect()
  const router = useRouter()
  const onMutationError = useOnMutationError(setError)
  const currency = useCurrency()

  return useMutation<any, any, any>(
    async ({ password }) => {
      const url = router?.query?.resetUrl?.toString()
      const processedUrl = window?.decodeURIComponent(url)

      return await resetPassword({
        store: currency,
        payload: { password, url: processedUrl },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        //TODO: standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        redirect(SINGLE_ROUTES.login)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
