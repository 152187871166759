import classnames from 'classnames/bind'
import React, { ReactNode } from 'react'

import Spinner from '~/components/Spinner'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LoadingPageProps {
  className?: string
  children?: ReactNode | ReactNode[]
}

function LoadingPage({ className, children }: LoadingPageProps) {
  return (
    <div className={cx(css.LoadingPage, className)}>
      {children ?? <Spinner className={css.loader} />}
    </div>
  )
}

LoadingPage.defaultProps = {}

export default LoadingPage
