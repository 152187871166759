import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { requestResetPassword } from '~/lib/handle-api'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useCurrency from '~/hooks/useCurrency'

export default function useShopifyRequestResetPasswordMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const currency = useCurrency()

  return useMutation<any, any, any>(
    async ({ email }) => {
      return await requestResetPassword({
        store: currency,
        payload: { email },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        //TODO: standardize error response
        //@ts-ignore
        if (data?.message !== 'ok') {
          const errorPayload = { payload: [{ code: 'UNIDENTIFIED_CUSTOMER' }] }
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        mutationOptions?.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        const errorPayload = {
          payload: [{ ...err, code: 'UNIDENTIFIED_CUSTOMER' }],
        }
        onMutationError(errorPayload, rest, mutationOptions?.onError)
      },
    },
  )
}
