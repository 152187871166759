import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useProfile from '~/hooks/account/useGetProfile'

export default function useAuthentication(
  redirectOnError: string,
  redirectOnSuccess?: string,
) {
  const router = useRouter()

  useEffect(() => {
    redirectOnError && router.prefetch(redirectOnError as string)
    redirectOnSuccess && router.prefetch(redirectOnSuccess as string)
  }, [])

  const profileQuery = useProfile(
    {
      refetchOnMount: true,
      keepPreviousData: true,
      retry: 0,
      onSuccess: (data) => {
        data && redirectOnSuccess && router.replace(redirectOnSuccess)
        !data && redirectOnError && router.replace(redirectOnError)
      },
    },
    {
      keys: ['authentication'],
    },
  )

  return profileQuery
}
