import React from 'react'

import LoadingPage from '~/components/LoadingPage'

import useAuthentication from '~/hooks/account/useAuthentication'

export default function withoutAuthentication(WrappedComponent) {
  const HOC = (pageProps) => {
    const { isLoading, data } = useAuthentication(null, '/account')

    return isLoading || data !== null ? (
      <LoadingPage />
    ) : (
      <WrappedComponent {...pageProps} />
    )
  }

  return HOC
}
