import classnames from 'classnames/bind'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { SINGLE_ROUTES } from '~/lib/constants'

import Email from '~/components/Account/Forms/Email'
import Password from '~/components/Account/Forms/Password'
import LayoutUnLogged from '~/components/Account/UnLogged/Layout'
import InlineCta from '~/components/InlineCta'
import { PanelForgotPassword, PanelRegister } from '~/components/Panels'
import RichText from '~/components/RichText'
import SquaredCta, { ButtonThemes } from '~/components/SquaredCta'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useCreateWishlist from '~/hooks/account/useCreateWishlist'
import useShopifyLoginMutation from '~/hooks/account/useShopifyLoginMutation'
import useFormWithMutation from '~/hooks/useFormWithMutation'
import useSingleRoutesRedirect from '~/hooks/useSingleRoutesRedirect'

import css from './styles.module.scss'
import { LayoutLoginProps, LoginProps } from './types'

const cx = classnames.bind(css)

function Login({ className, panel, redirectOnSuccess }: LoginProps) {
  const redirect = useSingleRoutesRedirect()

  const { add, data, removeCurrent } = usePanel()
  const { account } = data
  const { mutate: createWishlist } = useCreateWishlist()
  const t = useTranslate()

  const titleStyle = useStyle({ textPreset: 'text-13-18' })
  const ctaStyle = useStyle({ textPreset: 'cta-form-11' })

  const { mutate: loginMutation, isLoading } = useShopifyLoginMutation(
    {
      onSuccess: () => {
        removeCurrent()
        createWishlist({})

        if (redirectOnSuccess) {
          redirect(SINGLE_ROUTES.account)
        }
      },
    },
    (...params) => form.setError(...params),
  )

  const { form, onSubmit } = useFormWithMutation(loginMutation, null, 'login')

  return (
    <div className={cx(css.Login, className)}>
      <FormProvider {...form}>
        <form className={cx(css.form, { panel })} onSubmit={onSubmit}>
          <div className={cx(titleStyle, css.title)}>
            {account?.loginTitle && <RichText render={account.loginTitle} />}
          </div>
          <div className={css.center}>
            <Email className={css.firstInput} />
            <Password minLength={5} autoComplete="current-password" />
            <InlineCta
              className={cx(ctaStyle, css.forgotPassword)}
              asDiv
              onClick={(e) => {
                if (panel) add({ component: <PanelForgotPassword /> })
                else redirect(SINGLE_ROUTES.forgotPassword)
              }}>
              {t('account.forgot_password')}
            </InlineCta>
          </div>
          <div className={css.ctaWrapper}>
            <SquaredCta
              className={css.submit}
              withBackground
              withBorder
              fullWidth
              theme={ButtonThemes.Dark}
              isLoading={isLoading}
              type="submit">
              {t('account.login_submit')}
            </SquaredCta>
            <div className={cx(ctaStyle, css.register)}>
              <span>{t('account.first_visit')}</span>
              <InlineCta
                className={cx(ctaStyle, css.link)}
                onClick={(e) => {
                  e.preventDefault()
                  if (panel) add({ component: <PanelRegister /> })
                  else redirect(SINGLE_ROUTES.register)
                }}>
                {t('account.register')}
              </InlineCta>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

Login.defaultProps = {
  redirectOnSuccess: true,
}

export function LayoutLogin({
  backgroundImage,
  children,
  ...rest
}: LayoutLoginProps) {
  return (
    <LayoutUnLogged backgroundImage={backgroundImage}>
      <Login {...children} {...rest} />
    </LayoutUnLogged>
  )
}

export default Login
