import classnames from 'classnames/bind'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import Email from '~/components/Account/Forms/Email'
import LayoutUnLogged from '~/components/Account/UnLogged/Layout'
import {
  PasswordActionProps,
  LayoutPasswordActionsProps,
} from '~/components/Account/UnLogged/types'
import RichText from '~/components/RichText'
import SquaredCta from '~/components/SquaredCta'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useShopifyRequestResetPasswordMutation from '~/hooks/account/useShopifyRequestResetPasswordMutation'
import useFormWithMutation from '~/hooks/useFormWithMutation'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function ForgotPassword({ className }: PasswordActionProps) {
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false)
  const { data } = usePanel()
  const { account } = data
  const t = useTranslate()

  const titleStyle = useStyle({ textPreset: 'text-13-18' })
  const textStyle = useStyle({
    textPreset: 'text-12-14',
  })

  const { mutate: forgotMutation, isLoading } =
    useShopifyRequestResetPasswordMutation({
      onSuccess: () => setRequestSubmitted(true),
    })

  const { form, onSubmit } = useFormWithMutation(
    forgotMutation,
    null,
    'forgot_password',
  )

  return (
    <div className={cx(className, css.ForgotPassword)}>
      <div className={cx(titleStyle, css.title)}>
        {account?.forgotPasswordTitle && (
          <RichText render={account.forgotPasswordTitle} />
        )}
      </div>
      {requestSubmitted ? (
        account?.forgotPasswordTextSuccess && (
          <div className={cx(textStyle, css.subtitle)}>
            <RichText render={account.forgotPasswordTextSuccess} />
          </div>
        )
      ) : (
        <>
          {account?.forgotPasswordText && (
            <div className={cx(textStyle, css.subtitle)}>
              <RichText render={account.forgotPasswordText} />
            </div>
          )}
          <FormProvider {...form}>
            <form className={cx(css.form)} onSubmit={onSubmit}>
              <Email />
              <SquaredCta
                className={css.submit}
                withBackground
                withBorder
                isLoading={isLoading}
                type="submit">
                {t('account.send_email')}
              </SquaredCta>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  )
}

export function LayoutForgotPassword({
  backgroundImage,
  children,
}: LayoutPasswordActionsProps) {
  return (
    <LayoutUnLogged backgroundImage={backgroundImage}>
      <ForgotPassword {...children} />
    </LayoutUnLogged>
  )
}

export default ForgotPassword
